import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component';

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Tuesday 10th August 2021, we will be making the following changes to our mortgage product
      range:</p>
    <p><strong>Summary of changes</strong></p>

    <p><strong>New Business Customer Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 70%, 75% and 80% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 80% LTV have reduced</li>
    </ul>

    <p><strong>Existing Mortgage Customer Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 70%, 75%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 70%, 75%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 85% and 90% LTV have reduced</li>
    </ul>

    <p>There are no other changes to our rates or fixed rate end dates.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 10th August 2021 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate
      sheet</a></p>

  </NewsArticle>
);

export default Article;
